<template>
  <div class="px-1 py-2 bg-white shadow sm:px-6 sm:py-8">
    <h2 class="text-2xl mb-4">Submit rating</h2>
    <p>You can submit your experience with tasker. </p>
    <form class="mt-4"  @submit.prevent="submitRating" novalidate>
      <div class="items-center flex space-x-2">
        <vue3-star-ratings v-model="form.rating" :showControl="false" />
        <span class="pt-1">{{ form.rating }}</span>
      </div>
      <LabelTextArea
        class="my-5 w-full"
        inputClass="w-full"
        label="Comment"
        v-model="form.comment"
      />
      <SubmitButton class="w-auto mb-5" :isLoading="isLoading"
      >Submit </SubmitButton
    >
    <p class="text-green-500" v-if="isReviewSubmitted">Your review submitted successfully. Go to Home <a class="text-darkGreen" href="/">Click here</a> </p>
    </form>
  </div>
</template>
<script>
import { onMounted, ref } from 'vue'
import LabelTextArea from '@/components/LabelTextArea.vue'
import vue3StarRatings from 'vue3-star-ratings'
import SubmitButton from '@/components/SubmitButton.vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
export default {
  components: { vue3StarRatings, LabelTextArea, SubmitButton },
  setup (props) {
    const isLoading = ref(false)
    const isReviewSubmitted = ref(false)
    const store = useStore()
    const route = useRoute()
    const form = ref({
      rating: null,
      comment: null
    })
    // selected user
    const selectedUser = ref()
    const currentUser = ref({})
    onMounted(async () => {
      // selectedUser.value =
      selectedUser.value = route.params.id
      currentUser.value = await store.getters['auth/getUser'] // ("data/getUserProfile");
    })

    const submitRating = async () => {
      isLoading.value = true
      console.log(selectedUser.value, currentUser.value)

      const res = await store.dispatch('data/submitRating', { rating: form.value.rating, comment: form.value.comment, selectedUser: selectedUser.value, user: currentUser.value.id })
      // console.log(res, selectedUser.value, currentUser.value)
      alert(
        'Your rating has been submitted successfully, Thanks!'
      )
      isLoading.value = true
      if (res) {
        isReviewSubmitted.value = true
        setTimeout(() => {
          window.location = '/search'
        }, 3000)
      }
    }
    return { isLoading, form, submitRating, isReviewSubmitted }
  }
}
</script>
